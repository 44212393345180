// Definitions
import type { BenefitType } from "models/Benefits";
import type { ImageType } from "components/ui/Image/Image.types";
import { UserCountryCodesEnum } from "bus/user/models";

// Utils
import { default as HeroBg } from "assets/images/hero-bg.png";
import { default as HeroBgMobile } from "assets/images/hero-bg-mobile.jpg";
import { default as InTouchBg } from "assets/images/in-touch-bg.png";
import { default as Feedback1 } from "assets/images/index/feedback-1.jpg";
import { default as Feedback2 } from "assets/images/index/feedback-2.jpg";
import { default as Feedback3 } from "assets/images/index/feedback-3.jpg";
import { default as RetailerVideo } from "assets/images/retailer-video.jpg";
import { default as ProfitabilityPhoto } from "assets/images/profitability-photo.jpg";
import { default as SimplicityPhoto } from "assets/images/simplicity-photo.jpg";
import { default as ProximityPhoto } from "assets/images/intro-proximity.jpg";
import { default as Service1 } from "assets/images/index/service-1-brand-dealers-brand-agents.jpg";
import { default as Service2 } from "assets/images/index/service-2-garage-with-label.jpg";
import { default as Service3 } from "assets/images/index/service-3-garage-without-label.jpg";
import { default as Service4 } from "assets/images/index/service-4-car-center.jpg";
import { default as Service5 } from "assets/images/index/service-5-tyre-fitters.jpg";
import { default as Retailer } from "assets/images/retailer.jpg";
import { default as Tools } from "assets/images/intro/tools.jpg";

const introHeroLogo: { [key in UserCountryCodesEnum]: string } = {
  [UserCountryCodesEnum.de]: "/assets/images/intro/intro-hero-logo-de.svg",
  [UserCountryCodesEnum.fr]: "/assets/images/intro/intro-hero-logo-fr.svg",
  [UserCountryCodesEnum.en]: "/assets/images/intro/intro-hero-logo-en.svg",
  [UserCountryCodesEnum.at]: "/assets/images/intro/intro-hero-logo-de.svg",
  [UserCountryCodesEnum.nl]: "/assets/images/intro/intro-hero-logo-nl.svg",
};

export const introHero = {
  img: {
    src: HeroBg,
    alt: "intro:hero.imgAlt",
  },
  title: "intro:hero.title",
  desc: ["intro:hero.desc.0", "intro:hero.desc.1", "intro:hero.desc.2"],
  link: {
    text: "intro:hero.link",
  },
  imgMobile: {
    src: HeroBgMobile,
    alt: "intro:hero.imgAlt",
  },
  logo: introHeroLogo,
};

export const introInTouch = {
  img: {
    src: InTouchBg,
    alt: "intro:inTouch.imgAlt",
  },
  title: "intro:inTouch.title",
  desc: [
    {
      item: "intro:inTouch.items.0.desc",
    },
    {
      item: "intro:inTouch.items.1.desc",
    },
    {
      item: "intro:inTouch.items.2.desc",
    },
  ],
};

const benefitOptions: BenefitType[] = [
  {
    icon: "BenefitSearch",
    title: "intro:benefits.opts.0.title",
    description: "intro:benefits.opts.0.desc",
  },
  {
    icon: "BenefitManager",
    title: "intro:benefits.opts.1.title",
    description: "intro:benefits.opts.1.desc",
  },
  {
    icon: "BenefitPromo",
    title: "intro:benefits.opts.2.title",
    description: "intro:benefits.opts.2.desc",
  },
  {
    icon: "BenefitBonus",
    title: "intro:benefits.opts.3.title",
    description: "intro:benefits.opts.3.desc",
  },
  {
    icon: "BenefitDelivery",
    title: "intro:benefits.opts.4.title",
    description: "intro:benefits.opts.4.desc",
  },
  {
    icon: "BenefitReturn",
    title: "intro:benefits.opts.5.title",
    description: "intro:benefits.opts.5.desc",
  },
  {
    icon: "BenefitEstimate",
    header: "intro:benefits.opts.6.header",
    title: "intro:benefits.opts.6.title",
    description: "intro:benefits.opts.6.desc",
  },
  {
    icon: "BenefitCrm",
    title: "intro:benefits.opts.7.title",
    description: "intro:benefits.opts.7.desc",
  },
];

export const benefits = {
  title: "intro:benefits.title",
  opts: benefitOptions,
};

export const introLead = {
  title: "intro:lead.title",
  modalTitle: "intro:lead.modalTitle",
  btn: "intro:lead.btn",
};
export const introCallBack = {
  title: "intro:callBack.title",
  modalTitle: "intro:callBack.modalTitle",
  errors: {
    limit: {
      title: "intro:callBack.errors.limit.title",
      desc: "intro:callBack.errors.limit.desc",
    },
    personalLimit: {
      title: "intro:callBack.errors.personalLimit.title",
      desc: "intro:callBack.errors.personalLimit.desc",
      desc2: "intro:callBack.errors.personalLimit.desc2",
    },
  },
};

export const introFeedback = {
  title: "intro:feedback.title",
  details: [
    {
      id: 1,
      desc: "intro:feedback.slides.0.desc",
      author: {
        title: "intro:feedback.slides.0.author.title",
        desc: "intro:feedback.slides.0.author.desc",
      },
    },
    {
      id: 2,
      desc: "intro:feedback.slides.1.desc",
      author: {
        title: "intro:feedback.slides.1.author.title",
        desc: "intro:feedback.slides.1.author.desc",
      },
    },
    {
      id: 3,
      desc: "intro:feedback.slides.2.desc",
      author: {
        title: "intro:feedback.slides.2.author.title",
        desc: "intro:feedback.slides.2.author.desc",
      },
    },
  ],
  images: [
    {
      id: 1,
      src: Feedback1,
      alt: "intro:feedback.imgAlts.0",
    },
    {
      id: 2,
      src: Feedback2,
      alt: "intro:feedback.imgAlts.1",
    },
    {
      id: 3,
      src: Feedback3,
      alt: "intro:feedback.imgAlts.2",
    },
  ],
};

export const introAbout = {
  title: "intro:about.title",
  details: [
    {
      id: 1,
      title: "intro:about.slides.0.title",
    },
    {
      id: 2,
      title: "intro:about.slides.1.title",
    },
    {
      id: 3,
      title: "intro:about.slides.2.title",
    },
    {
      id: 4,
      title: "intro:about.slides.3.title",
    },
    {
      id: 5,
      title: "intro:about.slides.4.title",
    },
  ],
  images: [
    {
      id: 1,
      src: Service1,
      alt: "intro:about.imgAlts.0",
    },
    {
      id: 2,
      src: Service2,
      alt: "intro:about.imgAlts.1",
    },
    {
      id: 3,
      src: Service3,
      alt: "intro:about.imgAlts.2",
    },
    {
      id: 4,
      src: Service4,
      alt: "intro:about.imgAlts.3",
    },
    {
      id: 5,
      src: Service5,
      alt: "intro:about.imgAlts.4",
    },
  ],
};

export const retailerBrands: ImageType[] = [
  {
    id: 1,
    src: "/assets/images/brands/retailer-philips.svg",
    alt: "Philips",
  },
  {
    id: 2,
    src: "/assets/images/brands/retailer-ferodo.svg",
    alt: "Ferodo",
  },
  {
    id: 3,
    src: "/assets/images/brands/retailer-kyb.svg",
    alt: "Kyb",
  },
  {
    id: 4,
    src: "/assets/images/brands/retailer-good.svg",
    alt: "Good year",
  },
  {
    id: 5,
    src: "/assets/images/brands/retailer-bosch.svg",
    alt: "Bosch",
  },
  {
    id: 6,
    src: "/assets/images/brands/retailer-bridgestone.svg",
    alt: "Bridgestone",
  },
  {
    id: 7,
    src: "/assets/images/brands/retailer-kstools.svg",
    alt: "Kstools",
  },
  {
    id: 8,
    src: "/assets/images/brands/retailer-pierburg.svg",
    alt: "Pierburg",
  },
  {
    id: 9,
    src: "/assets/images/brands/retailer-castrol.svg",
    alt: "Castrol",
  },
  {
    id: 10,
    src: "/assets/images/brands/retailer-nissens.svg",
    alt: "Nissens",
  },
  {
    id: 11,
    src: "/assets/images/brands/retailer-gates.svg",
    alt: "Gates",
  },
  {
    id: 12,
    src: "/assets/images/brands/retailer-walker.svg",
    alt: "Walker",
  },
  {
    id: 13,
    src: "/assets/images/brands/retailer-brembo.svg",
    alt: "Brembo",
  },
  {
    id: 14,
    src: "/assets/images/brands/retailer-trw.svg",
    alt: "Trw",
  },
  {
    id: 15,
    src: "/assets/images/brands/retailer-mann.svg",
    alt: "Mann",
  },
  {
    id: 16,
    src: "/assets/images/brands/retailer-michelin.svg",
    alt: "Michelin",
  },
  {
    id: 17,
    src: "/assets/images/brands/retailer-valeo.svg",
    alt: "Valeo",
  },
  {
    id: 18,
    src: "/assets/images/brands/retailer-skf.svg",
    alt: "Skf",
  },
  {
    id: 19,
    src: "/assets/images/brands/retailer-febi.svg",
    alt: "Febi",
  },
  {
    id: 20,
    src: "/assets/images/brands/retailer-hella.svg",
    alt: "Hella",
  },
  {
    id: 21,
    src: "/assets/images/brands/retailer-luk.svg",
    alt: "Luk",
  },
  {
    id: 22,
    src: "/assets/images/brands/retailer-total.svg",
    alt: "Total",
  },
  {
    id: 23,
    src: "/assets/images/brands/retailer-moog.svg",
    alt: "Moog",
  },
  {
    id: 24,
    src: "/assets/images/brands/retailer-sachs.svg",
    alt: "Sachs",
  },
];

export const introRetailer = {
  head: "intro:retailer.head",
  title: "intro:retailer.title",
  desc: "intro:retailer.desc",
  img: {
    alt: "intro:retailer.imgAlt",
    src: Retailer,
  },
  video: {
    src: RetailerVideo,
    alt: "intro:retailer.videoAlt",
  },
  brands: retailerBrands,
};

export const freedom = {
  title: "intro:freedom.title",
  order: "intro:freedom.order",
  strings: "intro:freedom.strings",
  img1: {
    src: Tools,
    alt: "intro:freedom.img1Alt",
  },
};

const profitabilityOptions = [
  {
    icon: "Coupon",
    title: "intro:profitability.opts.0.title",
    description: "intro:profitability.opts.0.desc",
  },
  {
    icon: "TurnoverBonus",
    title: "intro:profitability.opts.1.title",
    description: "intro:profitability.opts.1.desc",
  },
  {
    icon: "FreeSpare",
    title: "intro:profitability.opts.2.title",
    description: "intro:profitability.opts.2.desc",
  },
  {
    icon: "SpecialPromo",
    title: "intro:profitability.opts.3.title",
    description: "intro:profitability.opts.3.desc",
  },
];

export const introProfitability = {
  img: {
    src: ProfitabilityPhoto,
    alt: "intro:profitability.imgAlt",
  },
  title: "intro:profitability.title",
  subtitle: "intro:profitability.subtitle",
  options: profitabilityOptions,
};

const simplicityOptions = [
  {
    icon: "FreeReturns",
    title: "intro:simplicity.opts.0.title",
    description: "intro:simplicity.opts.0.desc",
  },
  {
    icon: "CreationRepair",
    title: "intro:simplicity.opts.1.title",
    description: "intro:simplicity.opts.1.desc",
  },
  {
    icon: "SpecialSearch",
    title: "intro:simplicity.opts.2.title",
    description: "intro:simplicity.opts.2.desc",
  },
  {
    icon: "SimpleEasy",
    title: "intro:simplicity.opts.3.title",
    description: "intro:simplicity.opts.3.desc",
  },
];
export const introSimplicity = {
  img: {
    src: SimplicityPhoto,
    alt: "intro:simplicity.imgAlt",
  },
  title: "intro:simplicity.title",
  subtitle: "intro:simplicity.subtitle",
  options: simplicityOptions,
};

const proximityOptions = {
  account: {
    title: "intro:proximity.opts.account.title",
    items: [
      {
        icon: "AccountContact",
        description: "intro:proximity.opts.account.items.0.desc",
      },
      {
        icon: "AccountAvailableDays",
        description: "intro:proximity.opts.account.items.1.desc",
      },
      {
        icon: "AccountAlways",
        description: "intro:proximity.opts.account.items.2.desc",
      },
    ],
  },
  service: {
    title: "intro:proximity.opts.service.title",
    label: "intro:proximity.opts.service.label",
    items: [
      {
        icon: "ServiceDedicated",
        description: "intro:proximity.opts.service.items.0.desc",
      },
      {
        icon: "ServiceVisit",
        description: "intro:proximity.opts.service.items.1.desc",
      },
      {
        icon: "ServiceAssist",
        description: "intro:proximity.opts.service.items.2.desc",
      },
    ],
  },
};

export const introProximity = {
  img: {
    src: ProximityPhoto,
    alt: "intro:proximity.imgAlt",
  },
  title: "intro:proximity.title",
  subtitle: "intro:proximity.subtitle",
  options: proximityOptions,
};
